import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import reportErrorSentry from "src/lib/reportErrorSentry";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import QuestionnairePreviewCubit from "src/state/QuestionnairePreviewCubit/QuestionnairePreviewCubit";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import { BlocProvider, useBloc } from "src/state/state";
import CustomQuestionnaire from "src/ui/components/CustomQuestionnaire/CustomQuestionnaire";
import CustomQuestionnairePreviewSteps from "src/ui/components/CustomQuestionnairePreview/CustomQuestionnairePreviewSteps";
import { formatPlaceholders } from "src/ui/components/CustomQuestionnairePreview/helpers";
import useQuestionnaireTracking from "src/ui/components/CustomQuestionnairePreview/useQuestionnaireTracking";
import Link from "src/ui/components/Link/Link";
import Loader from "src/ui/components/Loader/Loader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider
} from "src/ui/styled/X";
import { CenterQuestionnaireContent } from "../AppQueryPopups/dialogs/LifestyleProgramDialog";

interface WrapperProps {
  children: React.ReactNode;
  isLifestyleQuestionnaire: boolean;
}

const Wrapper: React.FC<WrapperProps> = ({
  children,
  isLifestyleQuestionnaire
}) => {
  return isLifestyleQuestionnaire ? (
    <CenterQuestionnaireContent style={{ display: "flex" }}>
      {children}
    </CenterQuestionnaireContent>
  ) : (
    <>{children}</>
  );
};

let isLifestyleQuestionnaireGlobal = window.location.search.includes("lsq=true");

export const SectionTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

const CustomQuestionnaireDebugData: FC<{
  toggleListAll: () => void;
  listAllActive: boolean;
}> = (props) => {
  const [, { toggleView, isViewOpen }] = useBloc(QuestionnairePreviewCubit);
  const [
    { formMeta = {}, endScreens, logic, fields, hiddenFields, globalConfigs },
    { customFormVariables, generateOutputData, setActiveStep }
  ] = useBloc(QuestionnaireCubit);
  const location = useLocation();

  const accordionProps = (name: string): Record<string, unknown> => {
    return {
      expanded: isViewOpen(name),
      onChange: (): void => toggleView(name)
    };
  };

  const {
    title,
    type,
    id: formId
  } = formMeta as Record<string, string[] | string>;

  const answersJson = generateOutputData();

  const activeFieldLogic = useMemo(() => {
    const ref = location.hash.replace("#", "");
    const logicItems = logic.filter((item) => item.ref === ref);
    return JSON.stringify(logicItems, null, 2);
  }, [location.hash, logic]);

  const activeFieldData = useMemo(() => {
    const ref = location.hash.replace("#", "");
    const selected = fields.find((item) => item.ref === ref);
    return JSON.stringify(selected ?? {}, null, 2);
  }, [location.hash, fields]);

  const meta = [
    { title: "Title", data: title as string },
    { title: "Type", data: type as string },
    { title: "Form ID", data: formId as string }
  ];

  useEffect(() => {
    setActiveStep({
      to: {
        type: "field",
        value: location.hash.replace("#", "")
      }
    });
  }, [location.hash, fields]);

  const [isLifestyleQuestionnaire, setIsLifestyleQuestionnaire] = useState(
    isLifestyleQuestionnaireGlobal
  );

  return (
    <div>
      <div
        style={{
          position: "fixed",
          left: 10,
          top: 0,
          width: 500,
          display: "flex",
          justifyContent: "space-between"
        }}
      ></div>

      <Box
        style={{
          marginLeft: 20,
          width: 500,
          paddingRight: 20
        }}
      >
        <Box>
          <div style={{ paddingTop: 15 }}>
            <h5>Data Prefill</h5>
            <pre>
              {globalConfigs?.disableDataPrefill ? "Disabled" : "Enabled"}
            </pre>
            <Divider />
          </div>
        </Box>
        <Accordion {...accordionProps("meta")}>
          <AccordionSummary>
            <SectionTitle>Meta Data</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              {meta.map((item) => (
                <div key={item.title} style={{ paddingTop: 15 }}>
                  <h5>{item.title}</h5>
                  <pre>{item.data}</pre>
                  <Divider />
                </div>
              ))}
              <a
                style={{ color: "#000" }}
                href={`https://admin.typeform.com/form/${formId.toString()}/create`}
                target="_blank"
                rel="noreferrer"
              >
                Open in TypeForm
              </a>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("hidden")}>
          <AccordionSummary>
            <SectionTitle>
              Hidden Fields ({Object.keys(hiddenFields).length})
            </SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              {Object.keys(hiddenFields).map((key: string) => {
                return (
                  <div key={key}>
                    <h5>{key}</h5>
                    <pre>
                      <>{hiddenFields[key] ?? "--"}</>
                    </pre>
                  </div>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("vars")}>
          <AccordionSummary>
            <SectionTitle>
              Variables ({Object.keys(customFormVariables).length})
            </SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              {Object.keys(customFormVariables).map((item: string) => {
                return (
                  <div key={item} style={{ paddingTop: 15 }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <h5>{item}</h5>
                        <pre>
                          <>{typeof customFormVariables[item]} - </>
                          <>{customFormVariables[item] ?? "--"}</>
                        </pre>
                      </div>
                    </div>
                    <Divider />
                  </div>
                );
              })}
              {/* <nine-button onClick={(): void => editVar("new_var")}> */}
              {/*   Add Variable */}
              {/* </nine-button> */}
            </Box>
          </AccordionDetails>
        </Accordion>

        <CustomQuestionnairePreviewSteps
          accordionProps={accordionProps("steps")}
          toggleListAll={props.toggleListAll}
          listAllActive={props.listAllActive}
        />

        <Accordion {...accordionProps("logic")}>
          <AccordionSummary>
            <SectionTitle>Selected step logic</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <pre>{activeFieldLogic}</pre>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("stepdata")}>
          <AccordionSummary>
            <SectionTitle>Selected step data</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <pre>{activeFieldData}</pre>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("endscreens")}>
          <AccordionSummary>
            <SectionTitle>End Screens ({endScreens.length})</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              {endScreens.map((item) => (
                <div
                  key={item.id}
                  style={{
                    paddingTop: 15,
                    ...(location.hash === `#${item.ref}`
                      ? {
                          borderLeft: `4px solid orange`,
                          paddingLeft: 10
                        }
                      : {})
                  }}
                >
                  <Link style={{ color: "#000" }} to={`#${item.ref}`}>
                    <h5>{formatPlaceholders(item.title)}</h5>
                  </Link>

                  <Divider />
                </div>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("output")}>
          <AccordionSummary>
            <SectionTitle>Output Data</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <pre>{JSON.stringify(answersJson, null, 2)}</pre>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion {...accordionProps("tools")}>
          <AccordionSummary>
            <SectionTitle>Tools</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box width="100%">
              <nine-button
                onClick={(): void => {
                  StorageController.clear();
                  window.location.reload();
                }}
              >
                Clear All Cache
              </nine-button>
            </Box>
            <Box width="100%" style={{ marginTop: "24px" }}>
              <label>
                <input
                  type="checkbox"
                  checked={isLifestyleQuestionnaire}
                  onChange={() => {
                    setIsLifestyleQuestionnaire(!isLifestyleQuestionnaire);
                    isLifestyleQuestionnaireGlobal = !isLifestyleQuestionnaire;
                  }}
                />
                <span style={{ marginLeft: "4px", fontSize: "1.25rem" }}>
                  Is Lifestyle Questionnaire
                </span>
              </label>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

const AddPartnerToCustomFormVariables: FC = () => {
  const [, { customFormVariables }] = useBloc(QuestionnaireCubit);

  const [queryParams] = useSearchParams();
  const partner = queryParams.get("partner");

  useEffect(() => {
    if (partner?.length) {
      try {
        const decodedPartner = decodeURIComponent(partner ?? "");
        customFormVariables.partner = decodedPartner;
      } catch (error) {
        reportErrorSentry(error);
      }
    }
  }, [partner]);

  return null;
};

const CustomQuestionnairePreview: FC<{ iframe?: boolean }> = ({
  iframe = false
}) => {
  const [state, questionnaireBloc] = useBloc(QuestionnairePreviewCubit, {
    create: () => new QuestionnairePreviewCubit()
  });

  const { id = "" } = useParams<{ id: string }>();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [autoSkip] = useState(
    StorageController.getItem("debug.autoSkip") === "true"
  );

  const preview = !iframe;
  const [listAllActive, setListAllActive] = useState(false);

  const toggleListAll = (): void => {
    setListAllActive(!listAllActive);
  };

  useQuestionnaireTracking();

  useEffect(() => {
    ref.current?.scrollTo({
      top: 0,
      left: window.scrollX,
      behavior: "smooth"
    });
  }, [ref, location]);

  const [isLifestyleQuestionnaire, setIsLifestyleQuestionnaire] = useState(
    isLifestyleQuestionnaireGlobal
  );

  useEffect(() => {
    setIsLifestyleQuestionnaire(isLifestyleQuestionnaireGlobal);
  }, [isLifestyleQuestionnaireGlobal]);

  return (
    <BlocProvider bloc={questionnaireBloc}>
      <div
        key={state.key}
        ref={ref}
        style={
          {
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: iframe ? "center" : "flex-start",
            alignItems: iframe ? "center" : "flex-start",
            background: iframe ? "#F2EFE7" : "#FFFAF2",
            overflow: "auto"
          } as React.CSSProperties
        }
      >
        <Wrapper isLifestyleQuestionnaire={isLifestyleQuestionnaire}>
          <CustomQuestionnaire
            disableTracking
            listAll={listAllActive}
            autoSkip={autoSkip}
            preview={preview}
            id={id}
            useRouting
            onSubmit={async (): Promise<boolean> => {
              return false;
            }}
          >
            <AddPartnerToCustomFormVariables />
            {!iframe && (
              <div
                className="hide-mobile"
                style={{
                  position: "relative",
                  height: "calc(100vh)",
                  paddingTop: 60,
                  minWidth: 520,
                  paddingBottom: 20,
                  boxShadow: "2px 2px 10px rgba(0,0,0,.2)",
                  overflow: "auto"
                }}
              >
                <Loader>
                  <CustomQuestionnaireDebugData
                    toggleListAll={toggleListAll}
                    listAllActive={listAllActive}
                  />
                </Loader>
              </div>
            )}
          </CustomQuestionnaire>
        </Wrapper>
      </div>
    </BlocProvider>
  );
};

export default CustomQuestionnairePreview;
